<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 71px">
    <main class="page-content w-100">
      <div class="container-fluid row">
        <ul class="breadcrumb ProximaNovaRegular col" style="margin-left: 52px; margin-top: 12px">
          <!-- <li>
            <router-link to="/demo/hp/platform/models">
              <span>Model</span>
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/demo/hp/platform/stat">
              <span>Sale Statistics</span>
            </router-link>
          </li> -->
          <li>
            <router-link to="/demo/bfsi/platform/insights">
              <span>Future Planning</span>
            </router-link>
          </li>
          <li>
            <router-link to="/demo/bfsi/platform/future/planning-result">
              <span>Planning Result</span>
            </router-link>
          </li>
          <li class="ProximaNovaBold">Scenerio planning</li>
        </ul>
      </div>

      <!-- tab -->
      <div class="container-fluid">
        <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
        </w-tab>
      </div>
      <!-- Digital -->
      <div class="w-100  prediction-section" v-if="activeTab === 'Digital Media'">
        <div class="w-100" style="position: sticky; top: 71px; z-index: 9;">
          <div class="container-fluid tab-panel m-0 p-0">
            <div class="card w-100" style="box-shadow: rgb(233 235 239) 1px 2px 13px 5px;">
              <div class="card-body p-4">
                <div class="card-header font-large ProximaNovaBold" style="
                        padding: 0;
                        background: transparent;
                        border: 0px !important;
                      ">
                  Prediction
                  <div style="display: flex;
    justify-content: end;
    align-items: center;
    gap: 8rem;">
                    <!-- <span >Brand Marketing : 37%</span>  <span>Performance Marketing : 73%</span>  -->
                    <WhiteDropdown
                      :options="dropdownOptions"
                      :selectedOption="selectedOption"
                      class="mr-3"
                      @input="selectOption($event)"
                    />
                  </div>
                  <div class="float-right text-danger text-normal ProximaNovaRegular">
                    <span v-if="levelOneChecking.value !== 100">* Total growth is {{ levelOneChecking.value }} in
                      {{ levelOneChecking.name }}
                    </span>
                    <span v-else-if="checkLevelTwo.value !== 100">* Total growth is {{ checkLevelTwo.value }} inside
                      {{ checkLevelTwo.name }}
                    </span>
                    <span v-else-if="checkLevelThree.value !== 100">* Total growth is {{ checkLevelThree.value }} inside
                      {{ checkLevelThree.name }}
                    </span>
                    <span v-else>* Total growth is 100</span>
                  </div>
                  <div class="caption mt-3">
                    <span :class="
                      activeLevelTwo === '' && activeLevelOne === ''
                        ? 'text-bold'
                        : ''
                    ">{{ breadcrumb.first }}</span>
                    <span v-if="activeLevelOne !== ''" :class="
                      activeLevelOne !== '' && activeLevelTwo === ''
                        ? 'text-bold'
                        : ''
                    "><i class="fa fa-chevron-right mx-3"></i>{{ breadcrumb.second }}</span>
                    <span v-if="activeLevelTwo !== ''" :class="activeLevelTwo !== '' ? 'text-bold' : ''"><i
                        class="fa fa-chevron-right mx-3"></i>{{ breadcrumb.third }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid tab-panel card__body_content pt-5">
          <div class="row fix-width" style="flex-wrap: initial;">
            <div class="w-100 d-flex pb-5" style="overflow-x: auto;">
              <div class="col p-0" style="height: fit-content">
                <div class="newcard levelone" :class="[
                  activeLevelOne === levelOne.name ? 'active' : '',
                  activeLevelOne !== '' && activeLevelOne !== levelOne.name
                    ? 'fadeOutBox'
                    : '',
                  activeLevelOne === levelOne.name ? 'border_right_side' : '',
                ]" v-for="(levelOne, index) in chartData" :key="index">
                  <div @click="
                    showLevelTwoData(
                      levelOne.name,
                      levelOne.secondLevelData,
                      index
                    )
                  " class="w-100 py-3 px-4 d-flex font-medium ProximaNovaBold mb-5"
                    style="color:#222a37;justify-content: space-between;align-items: center;cursor:pointer;">
                    <div>
                      <span>{{ levelOne.name }}</span>
                    </div>
                    <div class="imagetoggle">
                      <img src="@/assets/eye-line.png" alt="close" height="30px"
                        v-if="activeLevelOne === levelOne.name" />
                      <img src="@/assets/eye-close-line.svg" alt="close" height="30px" v-else />
                    </div>
                  </div>
                  <div class="">
                    <Slider class="px-3" :defaultValue="levelOne.value" :pathColor="levelOne.pathColor"
                      :rangeColor="levelOne.backgroundColor" @updatedValue="updateLevelOneValue($event, index)" />
                  </div>
                  <div style="height:21%;cursor:pointer" @click="
                    showLevelTwoData(
                      levelOne.name,
                      levelOne.secondLevelData,
                      index
                    )
                  "></div>
                </div>
              </div>
              <div class="col p-0" :style="{ top: alignLevelTwoData + 'px' }" style="height: fit-content">
                <div class="w-100 wrapperLevelTwo  " :class="[
                  activeLevelOne !== '' ? 'border_top' : '',
                  activeLevelOne !== '' ? 'border_bottom' : '',
                ]">
                  <div class="newcard leveltwo my-4 " v-for="(levelOne, index) in activeLevelTwoData" :key="index"
                    :class="[
                      activeLevelTwo === levelOne.name ? 'active' : '',
                      activeLevelTwo !== '' && activeLevelTwo !== levelOne.name
                        ? 'fadeOutBox'
                        : '',
                      activeLevelTwo === levelOne.name
                        ? 'border_right_side'
                        : '',
                    ]">
                    <div class="w-100 py-3 px-4 d-flex font-medium ProximaNovaBold mb-5"
                      style="color:#222a37;justify-content: space-between;align-items: center;cursor:pointer" @click="
                        showLevelThreeData(
                          levelOne.name,
                          levelOne.thirdLevelData,
                          index
                        )
                      ">
                      <div>
                        <span>{{ levelOne.name }}</span>
                      </div>
                      <div class="imagetoggle">
                        <img src="@/assets/eye-line.png" alt="close" height="30px"
                          v-if="activeLevelTwo === levelOne.name" />
                        <img src="@/assets/eye-close-line.svg" alt="close" height="30px" v-else />
                      </div>
                    </div>
                    <div class="">
                      <Slider class="px-3" :defaultValue="levelOne.value" :pathColor="levelOne.pathColor"
                        :rangeColor="levelOne.backgroundColor" @updatedValue="updateLevelTwoValue($event, index)" />
                    </div>
                    <div style="height:21%;cursor:pointer" @click="
                      showLevelThreeData(
                        levelOne.name,
                        levelOne.thirdLevelData,
                        index
                      )
                    "></div>
                  </div>
                </div>
              </div>
              <div class="col p-0" style="height: fit-content" :style="{ top: alignLevelThreeData + 'px' }">
                <div class="w-100 wrapperLevelTwo" :class="[
                  activeLevelTwo !== '' ? 'border_top' : '',
                  activeLevelTwo !== '' ? 'border_bottom' : '',
                ]">
                  <div class="newcard leveltwo my-4 " v-for="(levelOne, index) in activeLevelThreeData" :key="index">
                    <div class="w-100 py-3 px-4 d-flex font-medium ProximaNovaBold mb-5"
                      style="color:#222a37;justify-content: space-between;align-items: center;">
                      <div>
                        <span>{{ levelOne.name }}</span>
                      </div>
                    </div>
                    <div class="">
                      <Slider class="px-3" :defaultValue="levelOne.value" :pathColor="levelOne.pathColor"
                        :rangeColor="levelOne.backgroundColor" @updatedValue="updateLevelThreeValue($event, index)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <w-scenarioplanning v-if="activeTab === 'Additional Details'"></w-scenarioplanning>
      <div class=" my-3 w-100 d-flex" style="justify-content: end;">
        <w-button style="float: none;" :buttonText="'Predict'" :buttonLoader="buttonLoader" @buttonClicked="handleClick"
          :isDisable="isActive" :class="{ resetPasswordLink: isActive }">
        </w-button>
      </div>
    </main>
  </div>
</template>

<script>
import Tab from "@/components/Solutions/Tab.vue";
import AdditionalDetails from "@/pages/Scenario/AdditionalDetails.vue";
import Button from "@/components/Profile/Button.vue";
import Slider from "@/widgets/Slider.vue";
import WhiteDropdown from '../../../widgets/WhiteDropdown.vue';
export default {
  name: "ScenarioPlanning",
  components: {
    "w-tab": Tab,
    "w-scenarioplanning": AdditionalDetails,
    "w-button": Button,
    Slider,
    WhiteDropdown,
  },
  data() {
    return {
      buttonLoader: "normal",
      activeLevelTwo: "",
      activeLevelOne: "",
      activeLevelTwoData: [],
      activeLevelThreeData: [],
      activeLevelOneIndex: 0,
      activeLevelTwoIndex: 0,
      dropdownOptions:[
        {id:"Overall", text:"Overall"},
        {id:"Brand Campaign", text:"Brand Compaign"},
        {id:"Performance Campaign", text:"Performance Campaign"}
      ],
      selectedOption:{id:"Overall", text:"Overall"},
      chartData: [
        {
          name: "Instagram1",
          value: 10,
          backgroundColor: "rgb(106,27,154)",
          secondLevelData: [
            {
              name: "Display Ads1",
              value: 10,
              backgroundColor: "#0033ff",
            },
          ],
        },
        {
          name: "Instagram111",
          value: 10,
          backgroundColor: "#0033ff",
          secondLevelData: [
            {
              name: "Display Ads1",
              value: 10,
              backgroundColor: "#0033ff",
            },
          ],
        },
        {
          name: "Instagram123",
          value: 10,
          backgroundColor: "#0033ff",
          secondLevelData: [
            {
              name: "Display Ads1",
              value: 10,
              backgroundColor: "#0033ff",
            },
          ],
        },
        {
          name: "Instagram",
          value: 10,
          backgroundColor: "#0033ff",
          secondLevelData: [
            {
              name: "Display Ads",
              value: 10,
              backgroundColor: "#0033ff",
            },
            {
              name: "Display Ads22",
              value: 10,
              backgroundColor: "#0033ff",
              thirdLevelData: [
                {
                  name: "Display Ads",
                  value: 10,
                  backgroundColor: "#0033ff",
                },
                {
                  name: "Display Ads",
                  value: 10,
                  backgroundColor: "#0033ff",
                },
              ],
            },
          ],
        },
      ],
      breadcrumb: {
        first: "Channel",
        second: "Ad Type",
        third: "Campaign Type",
      },
      activeTab: "Digital Media",
      tabs: [
        {
          category: "Digital Media",
        },
        // {
        //   category: "Content",
        // },
        {
          category: "Additional Details",
        },
      ],
      actualValues: {},
      colors: [
        "#1877f2",
        "#dd2a7b",
        "#148f3a",
        "#0077b5",
        "#b5a9ff",
        "#ffdd7c",
        "#08a0e9",
        "#dd2a7b",
        "#1877f2",
      ],
    };
  },
  created() {
    sessionStorage.setItem("onSP", "true")
    this.getSliderData();
  },
  mounted() { },
  computed: {
    isActive() {
      if (
        this.checkLevelThree.value === 100 &&
        this.levelOneChecking.value === 100 &&
        this.checkLevelTwo.value === 100 &&
        this.buttonLoader !== "loader"
      ) {
        return false;
      }
      return true;
    },
    alignLevelTwoData() {
      if (this.activeLevelOneIndex + 1 > this.activeLevelTwoData.length) {
        return (
          (this.activeLevelOneIndex + 1 - this.activeLevelTwoData.length) * 177
        );
      }
      return 0;
    },
    alignLevelThreeData() {
      if (this.activeLevelTwoIndex + 1 > this.activeLevelThreeData.length) {
        return (
          (this.activeLevelTwoIndex + 1 - this.activeLevelThreeData.length) *
          177 +
          this.alignLevelTwoData
        );
      }
      return 0 + this.alignLevelTwoData;
    },
    levelOneChecking() {
      var sum = 0;
      for (var i = 0; i < this.chartData.length; i++) {
        sum += this.chartData[i].value;
        if (i === this.chartData.length - 1) {
          if (sum !== 100) {
            return { name: "Level One", value: sum };
          }
        }
      }
      return { name: "", value: sum };
    },
    checkLevelTwo() {
      var sum = 0;
      for (var i = 0; i < this.chartData.length; i++) {
        sum = 0;
        for (var j = 0; j < this.chartData[i].secondLevelData.length; j++) {
          sum += this.chartData[i].secondLevelData[j].value;
        }
        if (sum !== 100) {
          return { name: this.chartData[i].name, value: sum };
        }
      }
      return { name: "", value: sum };
    },
    checkLevelThree() {
      var sum = 0;
      for (var i = 0; i < this.chartData.length; i++) {
        for (var j = 0; j < this.chartData[i].secondLevelData.length; j++) {
          sum = 0;
          for (
            var k = 0;
            k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
            k++
          ) {
            sum += this.chartData[i].secondLevelData[j].thirdLevelData[k].value;
            if (
              k ===
              this.chartData[i].secondLevelData[j].thirdLevelData.length - 1
            ) {
              if (sum !== 100) {
                return {
                  name:
                    this.chartData[i].name +
                    " > " +
                    this.chartData[i].secondLevelData[j].name +
                    " > " +
                    this.chartData[i].secondLevelData[j].thirdLevelData[k].name,
                  value: sum,
                };
              }
            }
          }
        }
      }
      return { name: "", value: sum };
    },
  },
  methods: {
    selectOption(e){
      this.selectedOption = e
    },
    getColor(color, index) {
      var opacity;
      if (index >= 5) {
        opacity = 0.5;
      } else {
        opacity = (10 - index) / 10;
      }
      var x = color.split(")");
      return x[0] + "," + opacity + ")";
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    updateLevelOneValue(e, i) {
      this.chartData[i].value = e;
      sessionStorage.setItem("edited", "edited");
    },
    updateLevelTwoValue(e, i) {
      this.activeLevelTwoData[i].value = e;
      sessionStorage.setItem("edited", "edited");
    },
    updateLevelThreeValue(e, i) {
      this.activeLevelThreeData[i].value = e;
      sessionStorage.setItem("edited", "edited");
    },

    showLevelTwoData(e, data, i) {
      this.activeLevelTwo = "";
      this.activeLevelThreeData = [];
      this.activeLevelTwoIndex = 0;
      if (e === this.activeLevelOne) {
        this.activeLevelOne = "";
        this.activeLevelTwoData = [];
        this.activeLevelOneIndex = 0;
        return;
      }
      this.activeLevelOne = e;
      this.activeLevelTwoData = data;
      this.activeLevelOneIndex = i;
    },
    showLevelThreeData(e, data, i) {
      if (e === this.activeLevelTwo) {
        this.activeLevelTwo = "";
        this.activeLevelThreeData = [];
        this.activeLevelTwoIndex = 0;
        return;
      }
      this.activeLevelTwo = e;
      this.activeLevelThreeData = data;
      this.activeLevelTwoIndex = i;
    },
    getSliderData() {
      const levelOneData = this.$route.params.levelOneData;
      const digitalLevelTwo = this.$route.params.digitalData;
      const tvlLevelTwo = this.$route.params.tvData;
      const sponsorshiplLevelTwo = this.$route.params.sponsorshipData;
      const printLevelTwo = this.$route.params.printData;
      const radioLevelTwo = this.$route.params.radioData;
      const billboardLevelTwo = this.$route.params.billboardData;
      const contentLevelTwo = this.$route.params.contentData;
      const tvThirdLevelData = this.$route.params.tvThirdLevelData;
      const digitalThirdLevelData = this.$route.params.digitalThirdLevelData;
      const printThirdLevelData = this.$route.params.printThirdLevelData;
      const radioThirdLevelData = this.$route.params.radioThirdLevelData;
      const billboardThirdLevelData = this.$route.params
        .billboardThirdLevelData;
      const sponsorshipThirdLevelData = this.$route.params
        .sponsorshipThirdLevelData;
      const contentThirdLevelData = this.$route.params.contentThirdLevelData;
      var levelOneColor = [
        "rgb(216,27,96)",
        "rgb(106,27,154)",
        "rgb(30,136,229)",
        "rgb(94,53,177)",
        "rgb(167,19,19)",
        "rgb(216,27,96)",
        "rgb(0,172,193)",
      ];
      this.chartData = [];
      for (var i = 0; i < levelOneData.length; i++) {
        var levelTwo = [];
        if (levelOneData[i].name.toLowerCase() === "Digital".toLowerCase()) {
          for (var j = 0; j < digitalLevelTwo.length; j++) {
            var levelThree = [];
            var localThreeData = digitalThirdLevelData.find(
              (obj) => obj.title === digitalLevelTwo[j].label
            ).value;
            for (var k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: digitalLevelTwo[j].label,
              value: digitalLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: digitalLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        } else if (levelOneData[i].name.toLowerCase() === "TV".toLowerCase()) {
          for (j = 0; j < tvlLevelTwo.length; j++) {
            levelThree = [];
            localThreeData = tvThirdLevelData.find(
              (obj) => obj.title === tvlLevelTwo[j].label
            ).value;
            for (k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: tvlLevelTwo[j].label,
              value: tvlLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: tvlLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        } else if (
          levelOneData[i].name.toLowerCase() === "Sponsorships".toLowerCase()
        ) {
          for (j = 0; j < sponsorshiplLevelTwo.length; j++) {
            levelThree = [];
            localThreeData = sponsorshipThirdLevelData.find(
              (obj) => obj.title === sponsorshiplLevelTwo[j].label
            ).value;
            for (k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: sponsorshiplLevelTwo[j].label,
              value: sponsorshiplLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: sponsorshiplLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        } else if (
          levelOneData[i].name.toLowerCase() === "Print".toLowerCase()
        ) {
          for (j = 0; j < printLevelTwo.length; j++) {
            levelThree = [];
            localThreeData = printThirdLevelData.find(
              (obj) => obj.title === printLevelTwo[j].label
            ).value;
            for (k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: printLevelTwo[j].label,
              value: printLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: printLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        } else if (
          levelOneData[i].name.toLowerCase() === "Radio".toLowerCase()
        ) {
          for (j = 0; j < radioLevelTwo.length; j++) {
            levelThree = [];
            localThreeData = radioThirdLevelData.find(
              (obj) => obj.title === radioLevelTwo[j].label
            ).value;
            for (k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: radioLevelTwo[j].label,
              value: radioLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: radioLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        } else if (
          levelOneData[i].name.toLowerCase() === "Content".toLowerCase()
        ) {
          for (j = 0; j < contentLevelTwo.length; j++) {
            levelThree = [];
            localThreeData = contentThirdLevelData.find(
              (obj) => obj.title === contentLevelTwo[j].label
            ).value;
            for (k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: contentLevelTwo[j].label,
              value: contentLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: contentLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        } else if (
          levelOneData[i].name.toLowerCase() === "Billboard".toLowerCase()
        ) {
          for (j = 0; j < billboardLevelTwo.length; j++) {
            levelThree = [];
            localThreeData = billboardThirdLevelData.find(
              (obj) => obj.title === billboardLevelTwo[j].label
            ).value;
            for (k = 0; k < localThreeData.length; k++) {
              levelThree.push({
                name: localThreeData[k].name,
                value: localThreeData[k].value,
                backgroundColor: this.colors[k],
              });
            }
            levelTwo.push({
              name: billboardLevelTwo[j].label,
              value: billboardLevelTwo[j].value,
              backgroundColor: this.getColor(levelOneColor[i], j),
              barColor: billboardLevelTwo[j].backgroundColor,
              thirdLevelData: levelThree,
            });
          }
          this.chartData.push({
            name: levelOneData[i].name,
            value: levelOneData[i].predicted,
            backgroundColor: levelOneColor[i],
            previous: levelOneData[i].previous,
            secondLevelData: levelTwo,
          });
        }
      }
    },
    handleClick() {
      var levelOneData = [];
      var digitalLevelTwoData = [];
      var contetnLeveleTwoData = [];
      var tvLeveleTwoData = [];
      var printLeveleTwoData = [];
      var digitalThirdLevelData = [];
      var contentThirdLevelData = [];
      var tvThirdLevelData = [];
      var printThirdLevelData = [];
      var sponsorshipLeveleTwoData = [];
      var sponsorshipThirdLevelData = [];
      var radioLeveleTwoData = [];
      var radioThirdLevelData = [];
      var billboardLeveleTwoData = [];
      var billboardThirdLevelData = [];
      for (var i = 0; i < this.chartData.length; i++) {
        if (this.chartData[i].name.toLowerCase() === "Digital".toLowerCase()) {
          for (var j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            var loacalValue = [];
            for (
              var k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            digitalThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            digitalLevelTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        } else if (
          this.chartData[i].name.toLowerCase() === "Content".toLowerCase()
        ) {
          for (j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            loacalValue = [];
            for (
              k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            contentThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            contetnLeveleTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        } else if (
          this.chartData[i].name.toLowerCase() === "TV".toLowerCase()
        ) {
          for (j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            loacalValue = [];
            for (
              k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            tvThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            tvLeveleTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        } else if (
          this.chartData[i].name.toLowerCase() === "Print".toLowerCase()
        ) {
          for (j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            loacalValue = [];
            for (
              k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            printThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            printLeveleTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        } else if (
          this.chartData[i].name.toLowerCase() === "Sponsorships".toLowerCase()
        ) {
          for (j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            loacalValue = [];
            for (
              k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            sponsorshipThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            sponsorshipLeveleTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        } else if (
          this.chartData[i].name.toLowerCase() === "Radio".toLowerCase()
        ) {
          for (j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            loacalValue = [];
            for (
              k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            radioThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            radioLeveleTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        } else if (
          this.chartData[i].name.toLowerCase() === "Billboard".toLowerCase()
        ) {
          for (j = 0; j < this.chartData[i].secondLevelData.length; j++) {
            loacalValue = [];
            for (
              k = 0;
              k < this.chartData[i].secondLevelData[j].thirdLevelData.length;
              k++
            ) {
              loacalValue.push({
                name: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .name,
                value: this.chartData[i].secondLevelData[j].thirdLevelData[k]
                  .value,
              });
            }
            billboardThirdLevelData.push({
              title: this.chartData[i].secondLevelData[j].name,
              value: loacalValue,
            });
            billboardLeveleTwoData.push({
              label: this.chartData[i].secondLevelData[j].name,
              value: this.chartData[i].secondLevelData[j].value,
              backgroundColor: this.chartData[i].secondLevelData[j].barColor,
            });
          }
        }
        levelOneData.push({
          name: this.chartData[i].name,
          predicted: this.chartData[i].value,
          previous: this.chartData[i].previous,
        });
      }
      this.$router.push({
        name: "AIMO-BFSI-Planning-results",
        params: {
          levelOneData: levelOneData,
          digitalData: digitalLevelTwoData,
          tvData: tvLeveleTwoData,
          sponsorshipData: sponsorshipLeveleTwoData,
          printData: printLeveleTwoData,
          radioData: radioLeveleTwoData,
          billboardData: billboardLeveleTwoData,
          contentData: contetnLeveleTwoData,
          digitalThirdLevelData: digitalThirdLevelData,
          tvThirdLevelData: tvThirdLevelData,
          sponsorshipThirdLevelData: sponsorshipThirdLevelData,
          printThirdLevelData: printThirdLevelData,
          radioThirdLevelData: radioThirdLevelData,
          billboardThirdLevelData: billboardThirdLevelData,
          contentThirdLevelData: contentThirdLevelData,
        },
      });
    },
  },
};
</script>

<style scoped>
.caption {
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #727988;
}

.newcard {
  min-width: 522px;
  height: 162px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
}

.newcard.active {
  border: 2px solid #000000;
}

.newcard.fadeOutBox {
  background-color: #f2f5fa;
  opacity: 0.5;
}

.newcard.levelone {
  margin-right: 48px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.newcard.leveltwo {
  margin: 15px 48px;
}

.btn-effect {
  margin: 20px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.border_right_side::before {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: 100%;
  top: 50%;
  width: 50px;
}

.border_top::before {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -1px;
  top: -1px;
  width: 50px;
}

.border_bottom::after {
  content: "";
  border-top: 2px solid #000;
  display: block;
  position: relative;
  left: -2px;
  top: 2px;
  width: 50px;
}

.wrapperLevelTwo {
  border-left: 2px solid #000;
  padding: 1px 0px;
}

.text-bold {
  font-weight: bold;
}

.card__body_content {
  box-shadow: rgb(233 235 239) -2px 4px 20px 11px;
  width: auto;
}

.fix-width {
  max-width: 100%;
  padding-left: 20px;
  padding-bottom: 20px;
}

.prediction-section {
  padding: 0px 30px;
}

.imagetoggle {
  height: 48px;
  width: 48px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .prediction-section {
    padding: 0 15px !important;
  }
}
</style>
